<template>
	<S2SForm title="Statements">
		<v-radio-group row v-model="statementType">
			<v-radio color="secondary" v-for="type in statementTypeItems" :key="type.id" :label="type.label" :value="type.id"></v-radio>
		</v-radio-group>

		<v-card class="mb-3">
			<v-card-title>
				<div class="subheading">Download Statements {{ statementType ? `for ${getStatementType()}` : "" }}</div>
			</v-card-title>
			<v-divider></v-divider>
			<v-container grid-list-xs>
				<v-layout row wrap>
					<v-flex sm5 xs12 pr-3>
						<v-select :items="dateItems" label="Statement for" item-text="label" return-object v-model="statementAction"></v-select>
					</v-flex>
					<v-flex sm5 xs12 pr-3>
						<v-select :items="downloadFileType" label="Download as" item-text="label" item-value="value" v-model="statementFileType"></v-select>
					</v-flex>
					<v-flex sm2 xs12 class="text-xs-left" d-flex align-center>
						<v-btn color="accent" :block="$vuetify.breakpoint.xsOnly" :disabled="!statementAction || !statementFileType" @click="doDownload()"
							>Download</v-btn
						>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card>
		<v-card>
			<v-card-title>
				<div class="subheading">Custom Date Ranges {{ statementType ? `for ${getStatementType()}` : "" }}</div>
			</v-card-title>
			<v-divider></v-divider>
			<v-container grid-list-xs>
				<v-layout row wrap>
					<v-flex sm4 xs12 pr-4>
						<v-text-field @click="dialogModal = true" label="From Date" prepend-icon="event" :value="dates.fromDate" readonly></v-text-field>
					</v-flex>
					<v-flex sm4 xs12 pr-4>
						<v-text-field @click="dialogModal = true" label="To Date" prepend-icon="event" :value="dates.toDate" readonly></v-text-field>
					</v-flex>
					<v-flex sm2 xs12 pr-4>
						<v-select
							:items="downloadFileType"
							label="Download as"
							item-text="label"
							item-value="value"
							v-model="customStatementFileType"
						></v-select>
					</v-flex>
					<v-flex sm2 xs12 class="text-xs-left" d-flex align-center>
						<v-btn color="accent" :block="$vuetify.breakpoint.xsOnly" :disabled="!dates.fromDate || !dates.toDate" @click="doDownload(dates)"
							>Download</v-btn
						>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card>
		<v-dialog ref="dialog" v-model="dialogModal" persistent width="initial">
			<div>
				<v-layout row wrap class="white" px-2 pb-2>
					<v-flex mr-2>
						<h4 style="background-color: white" class="py-2 pl-2">From Date: {{ dates.fromDate }}</h4>
						<v-date-picker v-model="dates.fromDate" type="date" scrollable no-title :max="dates.toDate"></v-date-picker>
					</v-flex>
					<v-flex>
						<h4 style="background-color: white" class="py-2 pl-2">To Date: {{ dates.toDate }}</h4>
						<v-date-picker v-model="dates.toDate" type="date" scrollable no-title :min="dates.fromDate"></v-date-picker>
					</v-flex>
				</v-layout>

				<v-flex>
					<v-card color="background" height="50px">
						<v-btn text color="primary" @click="dialogModal = false" block min-height="100%">Done</v-btn>
					</v-card>
				</v-flex>
			</div>
		</v-dialog>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { statement } from "@/api/statement-api";

export default Vue.extend({
	name: "OrganisationProfile",

	props: {
		orgId: {
			type: String,
			default: ""
		}
	},

	computed: {
		downloadFileType: function() {
			let options = [];

			if (this.statementType === 1) {
				// Payments
				options = [
					{ label: "PDF", value: "pdf" },
					{ label: "XLSX", value: "xlsx" }
				];
			} else {
				// Orders
				options = [
					{ label: "PDF", value: "pdf" },
					{ label: "CSV", value: "csv" }
				];
			}

			return options;
		}
	},

	data: function() {
		return {
			dateItems: [
				{ label: "Yesterday", action: "yesterday", id: 0 },
				{ label: "Last Week", action: "lastWeek", id: 1 },
				{ label: "Last Month", action: "lastMonth", id: 2 }
			],
			dates: {},
			statementTypeItems: [
				{ label: "Payments", id: 1 },
				{ label: "Orders", id: 2 }
			],
			dialogModal: false,

			statementAction: null,
			statementFileType: "xlsx",
			customStatementFileType: "xlsx",

			statementType: 1
		};
	},

	methods: {
		getStatementType() {
			return this.statementType == 1 ? "Payments" : "Orders";
		},
		doDownload(customDate) {
			const orgId = this.$props.orgId;
			let date = "";
			let format = "";

			if (customDate) {
				date = customDate;
				format = this.customStatementFileType;
			} else {
				date = this.timeFrame(this.statementAction.action);
				format = this.statementFileType;
			}

			const statementFor = this.getStatementType().toLowerCase();
			statement.downloadStatement(date.fromDate, date.toDate, format, statementFor, orgId);
		},
		timeFrame(date) {
			const nowDate = new Date();
			let fromDate = "";
			let toDate = "";

			switch (date) {
				case "yesterday": {
					nowDate.setDate(nowDate.getDate() - 1);
					fromDate = new Date(nowDate).toISOString().split("T")[0];
					toDate = fromDate;
					break;
				}

				case "lastWeek": {
					// set to Monday of this week
					nowDate.setDate(nowDate.getDate() - ((nowDate.getDay() + 6) % 7));

					// set to previous Monday
					fromDate = new Date(nowDate.setDate(nowDate.getDate() - 7)).toISOString().split("T")[0];
					toDate = new Date(nowDate.setDate(nowDate.getDate() + 6)).toISOString().split("T")[0];
					break;
				}

				case "lastMonth": {
					const year = nowDate.getFullYear();
					const month = nowDate.getMonth() - 1;

					const firstDay = new Date(year, month, 1);
					const lastDay = new Date(nowDate.getFullYear(), nowDate.getMonth(), 0);

					fromDate = new Date(firstDay).toISOString().split("T")[0];
					toDate = new Date(lastDay).toISOString().split("T")[0];

					break;
				}
			}

			return { fromDate: fromDate, toDate: toDate };
		}
	}
});
</script>
